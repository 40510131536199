<template>
  <b-card-code title="Créer un nouveau fournisseur">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de fournisseur </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Client"
              label-for="Client"
            >
              <validation-provider
                #default="{ errors }"
                name="Client"
                rules="required"
              >
                <b-form-input
                  v-model="supplier.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Client"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Téléphone 1"
              label-for="Téléphone 1"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone 1"
                rules="integer|length:8"
              >
                <b-form-input
                  v-model="supplier.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone "
                  maxlength="8"
                  type="number"
                  :formatter="formatPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Téléphone 2"
              label-for="Téléphone 2"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="integer|length:8"
              >
                <b-form-input
                  v-model="supplier.phone2"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone 2 "
                  maxlength="8"
                  type="number"
                  :formatter="formatPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="supplier.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  type="email"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Matricule Fiscal"
              label-for="matricule"
            >
              <validation-provider
                #default="{ errors }"
                name="matricule"
              >
                <b-form-input
                  v-model="supplier.tax_identification_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Matricule Fiscal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Adresse"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
              >
                <b-form-input
                  v-model="supplier.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Mot de passe"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8"
              >
                <b-form-input
                  id="a-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- confirm password -->
          <b-col cols="6">
            <b-form-group
              label="Confirmer mot de passe"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="ac-password"
                  v-model="supplier.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Confirm Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
          >
            <b-form-group
              label="Dépot"
              label-for="depot"
            >
              <validation-provider
                #default="{ errors }"
                name="depot"
                rules="required"
              >
                <v-select
                  v-model="supplier.repository"
                  :clearable="false"
                  placeholder="Dépots"
                  label="name"
                  :options="repositories"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="mb-1 mt-1"
          >
            <b-form-checkbox
              v-model="supplier.weight_price"
              name="check-button"
              switch
              inline
            >
              Paramétrer le prix selon le poids volumétrique de colis
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="supplier.weight_price == false"
            md="6"
          >            <b-form-group
            label="Prix de livraison"
            label-for="Prix de livraison"
          >
            <validation-provider
              #default="{ errors }"
              name="Prix de livraison"
              rules="required"
            >
              <b-form-input
                v-model="supplier.price_delivery"
                :state="errors.length > 0 ? false : null"
                placeholder="Prix de livraison"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          </b-col>
          <b-col
            v-if="supplier.weight_price == false"
            md="6"
          >
            <b-form-group
              label="Prix de retour"
              label-for="Prix de retour"
            >
              <validation-provider
                #default="{ errors }"
                name="Prix de retour"
                rules="required"
              >
                <b-form-input
                  v-model="supplier.price_return"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix de retour"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="supplier.weight_price == true"
            cols="12"
          >
            <div>
              <b-form
                ref="form"
                :style="{ height: trHeight }"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >

                  <!-- produit -->
                  <b-col md="2">
                    <b-form-group
                      label="Poids volumétrique"
                      label-for="Poids volumétrique"
                    >
                      <v-select
                        v-model="item.weight"
                        :clearable="false"
                        placeholder="Poids volumétrique"
                        label="weight"
                        :options="weights"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Prix de livraison"
                      label-for="Prix de livraison"
                    >
                      <b-form-input
                        v-model="item.delivery_price"
                        placeholder="Prix de livraison"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Prix de retour"
                      label-for="Prix de retour"
                    >
                      <b-form-input
                        id="Prix de retour"
                        v-model="item.return_price"
                        type="number"
                        placeholder="Prix de retour"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Supprimer</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-form>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mb-2"
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Nouveau poids</span>
            </b-button>

          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isSupplierValid }"
              :disabled="isLoading || !isSupplierValid"
              @click.prevent="addSupplier"
            >
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    // BFormCheckbox,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          delivery_price: '',
          return_price: '',
          weight: '',
        },
      ],
      weights: [],
      weights_orders: [],
      password: '',
      repositories: [],
      supplier: {
        price_return: 0,
        price_delivery: 0,
        username: '',
        email: '',
        password: '',
        address: '',
        repository: '',
        tax_identification_number: '',
        weight_price: false,
      },
      required,
      isLoading: false,
    }
  },
  computed: {
    isSupplierValid() {
      return (
        this.supplier.email !== ''
        && this.supplier.password !== ''
        && this.supplier.username !== ''
        && this.supplier.phone !== ''
        && this.supplier.tax_identification_number !== ''
        && this.supplier.address !== ''
      )
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.getRepositories()
    this.getWeights()
  },
  methods: {
    async getWeights() {
      this.isLoading = true
      const { data } = await axios.get('/api/weights/')
      this.weights = data
      this.isLoading = false
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    repeateAgain() {
      this.items.push({
        weight: '',
        delivery_price: '',
        return_price: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
    async addSupplier() {
      if (this.supplier.weight_price === false) {
        this.weights_orders = []
      } else {
        this.weights_orders = this.items.map(x => x.weight.id)
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios.post('/api/auth/register/supplier/',
              {
                email: this.supplier.email,
                password: this.supplier.password,
                username: this.supplier.username,
                phone: this.supplier.phone,
                phone2: this.supplier.phone2,
                tax_identification_number: this.supplier.tax_identification_number,
                price_return: this.supplier.price_return,
                price_delivery: this.supplier.price_delivery,
                address: this.supplier.address,
                repository: this.supplier.repository.id,
                prices: this.items,
                weights: this.weights_orders,
                weight_price: this.supplier.weight_price,
              })
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Fournisseur ajouté avec succés')
            }, 1000)
            this.$router.push('/suppliers')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
